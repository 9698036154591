import { lazy, Suspense, useEffect, useState } from 'react';

/// Components

import Index from "./jsx";
import { connect, useDispatch } from 'react-redux';
import {  Route, Routes, useLocation , useNavigate , useParams } from 'react-router-dom';

// action
import { checkAutoLogin } from './services/AuthService';
import { isAuthenticated } from './store/selectors/AuthSelectors';
import { SuperadminloginApi } from './services/superadminService/SuperAdminAuthService';
import { adminloginApi } from './services/AuthService';
// import 'react-paginate/dist/react-paginate.css';
// import "./vendor/bootstrap-select/dist/css/bootstrap-select.min.css";
import "./css/style.css";



const SignUp = lazy(() => import('./jsx/pages/Registration'));
const ForgotPassword = lazy(() => import('./jsx/pages/ForgotPassword'));
const Login = lazy(() => {
    return new Promise(resolve => {
    setTimeout(() => resolve(import('./jsx/pages/Login')), 500);
  });
});

const TokenKey  = localStorage.getItem("access_token")


function withRouter(Component) {
  function ComponentWithRouterProp(props) {
    let location = useLocation();
    let navigate = useNavigate();
    let params = useParams();
	
    return (
      <Component
        {...props}
        router={{ location, navigate, params }}
      />
    );
  }

  return ComponentWithRouterProp;
}

function App (props) {
    const dispatch = useDispatch();
	const navigate = useNavigate();
    // useEffect(() => {
    //     checkAutoLogin(dispatch, navigate);
    // }, []);
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    // useEffect(() => {
    //     const token = localStorage.getItem("access_token");
    //     setIsAuthenticated(!!token);
    // }, []);

    useEffect(() => {
        const token = localStorage.getItem("access_token");
      
        setIsAuthenticated(!!token);

      
    }, [localStorage.getItem("access_token")]);

     
    
    let routesBlog = (  
        <Routes>
            <Route path='/' element={<Login apifun={adminloginApi}/>} />
            <Route path='/admin-login' element={<Login apifun={adminloginApi}/>} />
            <Route path='/super-admin-login' element={<Login apifun={SuperadminloginApi}/>} />
    
            <Route path='/page-register' element={<SignUp />} />
            <Route path='/page-forgot-password' element={<ForgotPassword/>} />
            {/* <Route path='*' element={<Navigate to="/super-admin-login" replace />} /> */}
        </Routes>
    );
    if (isAuthenticated) {
        //console.log("isAuthenticated",isAuthenticated);
		return (
			<>
                <Suspense fallback={
                    <div id="preloader">
                        <div className="sk-three-bounce">
                            <div className="sk-child sk-bounce1"></div>
                            <div className="sk-child sk-bounce2"></div>
                            <div className="sk-child sk-bounce3"></div>
                        </div>
                    </div>  
                   }
                >
                    <Index / >
                </Suspense>
            </>
        );
	
	}else{
		return (
			<div className="vh-100">
                <Suspense fallback={
                    <div id="preloader">
                        <div className="sk-three-bounce">
                            <div className="sk-child sk-bounce1"></div>
                            <div className="sk-child sk-bounce2"></div>
                            <div className="sk-child sk-bounce3"></div>
                        </div>
                    </div>
                  }
                >
                    {routesBlog}
                </Suspense>
			</div>
		);
	}
};

{/* const mapStateToProps = (state) => {
    return {
        isAuthenticated: isAuthenticated(TokenKey),
    };
}; */}


export default withRouter(App);