import React from "react";
import { Route } from "react-router-dom";
import tableConfig from "../config/tableConfig";
// Import admin-specific components
import Dashboard from "../jsx/components/Dashboard/Home";
import Home from "../jsx/components/Dashboard/Home";
import {
  getAllSubCategory,
  getAllUserCategory,
  getAllUserLevel,
} from "../services/superadminService/UserLevel";
import CommonTable from "../jsx/components/CommonTable/CommonTable";
import CategoryTypeList from "../admin/admincomponent/categoryType/CategoryTypeList";
import SubCategoryList from "../admin/admincomponent/subCategory/SubCategoryList";
import OrganizationList from "../admin/admincomponent/organization/OrganizationList";
import IndividualList from "../admin/admincomponent/Individual/IndividualList";
import IndividualVerificationForm from "../admin/admincomponent/Individual/IndividualVerificationForm";
import OrganizationVerficationForm from "../admin/admincomponent/organization/OrganizationVerficationForm";
import VerifiedUserList from "../admin/admincomponent/verifiedUser/VerifiedUserList";
import viewUser from "../admin/admincomponent/verifiedUser/ViewUser";
import ViewUser from "../admin/admincomponent/verifiedUser/ViewUser";
import InsituteViewUser from "../admin/admincomponent/verifiedUser/InsituteViewUser";
import InsituteVeriedUserList from "../admin/admincomponent/verifiedUser/InsituteVeriedUserList";
import ImportExcelModal from "../admin/admincomponent/organization/ImportExcelModal";
import Communication from "../admin/admincomponent/communication/Communication";
import CommunicationList from "../admin/admincomponent/communication/CommunicationList";
import WebsiteNotification from "../admin/admincomponent/communication/WebsiteNotification/WebsiteNotification";
import Ticket from "../admin/admincomponent/ticket/Ticket";
import EventCalendar from "../admin/admincomponent/eventCalendar/EventCalendar";
import ProfileComp from "../admin/admincomponent/Profile/ProfileComp";

export const allAdminroutes = [
  /// Dashboard
  { url: "", component: <Home /> },
  { url: "dashboard", component: <Home /> },
  { url: "Organization", component: <OrganizationList /> },
  { url: "Individual", component: <IndividualList /> },
  {
    url: "verification-form/:id",
    component: <OrganizationVerficationForm roleWiseType="insitute" roletype="2"/>,
  },
  {
    url: "verification-Individual-form/:id",
    component: <IndividualVerificationForm roleWiseType="individual" roletype="1" />,
  },
  {
    url: "verified-insitute-user",
    component: <InsituteVeriedUserList roletype="2" roleCategory="insitute" />,
  },
  {
    url: "verified-individual-user",
    component: <VerifiedUserList roletype="1" roleCategory="individual" />,
  },
  { url: "verification-view-form/:id", component: <ViewUser /> },
  {
    url: "verification-insitute-view-form/:id",
    component: <InsituteViewUser />,
  },
  { url: "communication", component: <Communication /> },
  { url: "communication/communication-list", component: <CommunicationList /> },
  { url: "grievance-management", component: <Ticket /> },
  {
    url: "communication/website-notification-list",
    component: <WebsiteNotification />,
  },
  {
    url: "event/event-calendar",
    component: <EventCalendar />,
  },
  {
    url: "profile",
    component: <ProfileComp />,
  },
  // { url: "ExcelImport", component: <ImportExcelModal/> },

  // { url: 'user-category', component: <CommonTable fetchData={getAllUserCategory} config={tableConfig['user-category']} /> },
  // { url: 'user-sub-category', component: <SubCategoryList  /> },
  // { url: 'user-category-type', component: <CategoryTypeList /> },
];
